const CMS_BASE_URL = '';

const routes = {
  // Admin Panel Routes
  CMS_USERS: `${CMS_BASE_URL}/korisnici`,
  CMS_JAKOV_LAGER: `${CMS_BASE_URL}/jakov-lager`,
  CMS_ADD_USER: `${CMS_BASE_URL}/dodaj-korisnika`,
  CMS_EDIT_USER: `${CMS_BASE_URL}/korisnici/:user_id`,
  CMS_INVOICES: `${CMS_BASE_URL}/profakture`,
  CMS_INVOICE: `${CMS_BASE_URL}/profakture/:invoice_id`,
  CMS_INVOICES_DATA: `${CMS_BASE_URL}/podaci`,
  CMS_INVOICES_EMAILS: `${CMS_BASE_URL}/profakture-emailovi`,
  CMS_INVOICES_BANKS: `${CMS_BASE_URL}/profakture-banke`,
  CMS_PRODUCTS: `${CMS_BASE_URL}/proizvodi`,
  CMS_PRODUCTS_VISIBLE_ON_SITE: `${CMS_BASE_URL}/vidljivi-na-sajtu`,
  CMS_E_OFFER: `${CMS_BASE_URL}/e-ponuda-export`,
  CMS_E_OFFER_IMPORT: `${CMS_BASE_URL}/e-ponuda-import`,
  CMS_ADD_PRODUCT: `${CMS_BASE_URL}/dodaj-proizvod`,
  CMS_PRODUCT: `${CMS_BASE_URL}/proizvodi/:product_id`,
  CMS_PRODUCT_DISCOUNT: `${CMS_BASE_URL}/proizvodi/:product_id/akcije`,
  CMS_PRODUCT_ATTRIBUTES: `${CMS_BASE_URL}/proizvodi/:product_id/atribut`,
  CMS_PRODUCT_PHOTOS: `${CMS_BASE_URL}/proizvodi/:product_id/slike`,
  CMS_PRODUCT_STICKER: `${CMS_BASE_URL}/proizvodi/:product_id/stiker`,
  CMS_PRODUCT_CATALOG_STICKER: `${CMS_BASE_URL}/proizvodi/:product_id/katalog_stiker`,
  CMS_PRODUCTS_CREATE_ATTRIBUTE: `${CMS_BASE_URL}/proizvodi/dodaj-atribute/:products_category_attribute_id`,
  CMS_CATEGORIES: `${CMS_BASE_URL}/kategorije`,
  CMS_ADD_CATEGORY: `${CMS_BASE_URL}/dodaj-kategoriju`,
  CMS_CATEGORY: `${CMS_BASE_URL}/kategorije/:category_id`,
  CMS_CATEGORY_WEB_MARGINS: `${CMS_BASE_URL}/web-margins-categories`,
  CMS_WEB_MARGINS: `${CMS_BASE_URL}/web-marže`,
  CMS_ADD_WEB_MARGIN: `${CMS_BASE_URL}/dodaj-web-maržu`,
  CMS_EDIT_WEB_MARGIN: `${CMS_BASE_URL}/web-marže/:margin_id`,
  CMS_RECOMMENDED_PRICE: `${CMS_BASE_URL}/preporučene-cene`,
  CMS_ADD_RECOMMENDED_PRICE: `${CMS_BASE_URL}/dodaj-preporučenu-cenu`,
  CMS_ADD_CONDITION: `${CMS_BASE_URL}/dodaj-kriterijum`,
  CMS_ADD_EXCLUSION: `${CMS_BASE_URL}/dodaj-izuzetak`,
  CMS_REMOVE_CONDITION: `${CMS_BASE_URL}/izuzmi-kriterijum`,
  CMS_EDIT_RECOMMENDED_PRICE: `${CMS_BASE_URL}/preporučene-cene/:recommended_price_id`,
  CMS_EDIT_CONDITION: `${CMS_BASE_URL}/jakov-lager/:condition_id`,
  CMS_EDIT_EXCLUSION: `${CMS_BASE_URL}/jakov-exclusion/:condition_id`,
  CMS_ATTRIBUTES: `${CMS_BASE_URL}/atributi`,
  CMS_BRANDS: `${CMS_BASE_URL}/brendovi`,
  CMS_UPLOAD_PRICELIST: `${CMS_BASE_URL}/ažuriraj-cenovnik`,
  CMS_OUR_SUPPLIERS: `${CMS_BASE_URL}/naši-dobavljači`,
  CMS_OUR_SUPPLIERS_PRODUCTS: `${CMS_BASE_URL}/naši-dobavljači/:id`,
  CMS_CATALOG_STICKERS: `${CMS_BASE_URL}/katalog-nalepnice`,
  CMS_PROMO_CODE: `${CMS_BASE_URL}/promo-kod`,
  CMS_LINKING_CATEGORIES: `${CMS_BASE_URL}/povezivanje-kategorija`,
  CMS_LINKING_PRODUCTS: `${CMS_BASE_URL}/povezivanje-proizvoda`,
  CMS_ACTIVITY_LOG: `${CMS_BASE_URL}/aktivnosti-korisnika`,
  CMS_AUTH_DISCOUNTS: `${CMS_BASE_URL}/autentifikovani-popust`,
  CMS_ADD_AUTH_DISCOUNTS: `${CMS_BASE_URL}/dodaj-autentifikovani-popust`,
  CMS_EDIT_AUTH_DISCOUNTS: `${CMS_BASE_URL}/autentifikovani-popust/:auth_discount_id`,
  CMS_DISCOUNTS_TYPES: `${CMS_BASE_URL}/tipovi-akcija`,
  CMS_ORDERS: `${CMS_BASE_URL}/porudžbine`,
  CMS_SHOW_ORDER: `${CMS_BASE_URL}/porudžbine/:order_id`,
  CMS_BANNERS: `${CMS_BASE_URL}/baneri`,
  CMS_ADD_BANNER: `${CMS_BASE_URL}/dodaj-baner`,
  CMS_EDIT_BANNER: `${CMS_BASE_URL}/baneri/:banner_id`,
  CMS_HOME_SCREEN_PRODUCTS: `${CMS_BASE_URL}/početna-strana-proizvodi`,
  CMS_HOME_SCREEN_CATEGORIES: `${CMS_BASE_URL}/početna-strana-kategorije`,
  CMS_HOME_SCREEN_SECTIONS: `${CMS_BASE_URL}/početna-strana-sekcije`,
  CMS_HOME_SCREEN_ADD_SECTION: `${CMS_BASE_URL}/početna-strana-dodaj-sekciju`,
  CMS_HOME_SCREEN_SECTION: `${CMS_BASE_URL}/početna-strana-sekcije/:section_id`,
  CMS_NEWS: `${CMS_BASE_URL}/vesti`,
  CMS_ADD_NEWS: `${CMS_BASE_URL}/dodaj-vest`,
  CMS_EDIT_NEWS: `${CMS_BASE_URL}/vesti/:news_id`,
  CMS_AUTHORIZATION: `${CMS_BASE_URL}/autorizacija`,
  CMS_NEWSLETTER: `${CMS_BASE_URL}/newsletter`,
  CMS_NOTIFICATIONS: `${CMS_BASE_URL}/notifikacije`,
  CMS_RATINGS: `${CMS_BASE_URL}/ocene`,
  CMS_RATING: `${CMS_BASE_URL}/ocene/:rating_id`,
  CMS_QUESTIONS_AND_ANSWERS: `${CMS_BASE_URL}/pitanja-i-odgovori`,
  CMS_SHOW_QUESTIONS_AND_ANSWERS: `${CMS_BASE_URL}/pitanja-i-odgovori/:questions_and_answers_id`,
  CMS_PRODUCTS_EXPORT: `${CMS_BASE_URL}/export-proizvoda`,
  CMS_PRODUCTS_EXPORT_ADD_CLIENT: `${CMS_BASE_URL}/export-proizvoda/dodaj-klijenta`,
  CMS_PRODUCTS_IMPORT_CATEGORY_TO_CATEGORY: `${CMS_BASE_URL}/import-proizvoda/kategorija-u-kategoriju`,
  CMS_PRODUCTS_IMPORT_PRODUCT_TO_CATEGORY: `${CMS_BASE_URL}/import-proizvoda/proizvod-u-kategoriju`,
  CMS_ACTIONS: `${CMS_BASE_URL}/akcije`,
  CMS_API_CLIENT: `${CMS_BASE_URL}/api-klijent`,
  CMS_LANDING_PAGE: `${CMS_BASE_URL}/landing-page`,
  CMS_SEO: `${CMS_BASE_URL}/seo`
  // // Website Routes
  // HOME: '/',
  // LOGIN: '/login',
  // REGISTER: '/registracija',
  // SEARCH_RESULT: `/pretraga-proizvoda/:product_name`,
  // MY_ACCOUNT_ORDERS: '/moj-nalog/porudžbine',
  // MY_ACCOUNT_SHOW_ORDER: '/moj-nalog/porudžbine/:account_order_id',
  // MY_ACCOUNT_ADDRESSES: '/moj-nalog/adrese',
  // MY_ACCOUNT_ADDRESSES_FORM: '/moj-nalog/adrese/:address_method',
  // MY_ACCOUNT_ACCOUNT_DETAILS: '/moj-nalog/detalji-naloga',
  // PRODUCT: '/kategorije/:web_category_slug/proizvodi/:web_product_slug',
  // // delete me
  // PRODUCT_COPY:
  //   '/kategorije_copy/:web_category_slug/proizvodi/:web_product_slug',
  // PRODUCT_CATEGORIES: '/kategorije/:category_product_slug',
  // NEWS: '/vesti',
  // NEWS_PREVIEW: '/vesti/:website_news_id',
  // ACTIONS: '/sve-akcije',
  // ACTIONS_PREVIEW: '/proizvodi-na-akciji/:action_preview_id',
  // SINGLE_ACTION: '/sve-akcije/:action_slug',
  // WISH_LIST: '/lista-želja',
  // SHOPPING_CART: '/korpa',
  // CONTACT: '/kontakt',
  // EQUIP_OFFICE: '/opremite-kancelariju',
  // EQUIP_HOME: '/opremite-dom',
  // ABOUT: '/o-nama',
  // HELP: '/pomoc',
  // JOBS: '/posao',
  // OPEN_POSITIONS: '/otvorene-pozicije',
  // WEB_CREDIT: '/web-kredit',
  // SERVICE: '/servis',
  // SERVICE_LAPTOP: '/servis-laptopova',
  // SERVICE_PC: '/servis-racunara',
  // SERVICE_PRINTERS: '/servis-stampaca',
  // SERVICE_MONITORS: '/servis-monitora',
  // SERVICE_COMPUTER_NETWORK: '/racunarske-mreže',
  // SERVICE_CONTACT: '/servis-kontakt',
  // TERMS_AND_PRICES_OF_DELIVERY: '/uslovi-i-cene-isporuke-robe',
  // PRIVACY_POLICY: '/politika-privatnosti',
  // TERMS_AND_CONDITIONS: '/pravila-i-uslovi-koriscanja',
  // CREDITS: '/nacini-placanja/krediti',
  // DISCOUNTED_CASH: '/nacini-placanja/gotovina-sa-popustom',
  // INTEREST_FREE_ACCOUNT: '/nacini-placanja/cekovi-bez-kamate',
  // PAY_CARDS: '/nacini-placanja/platne-kartice',
  // REFUND_PDV: '/nacini-placanja/povracaj-pdv-a',
  // COMPLETION_PURCHASE: '/zavrsetak-kupovine',
  // THANKS_PAGE: '/uspesna-kupovina',
  // NEWSLETTER_UNSUBSCRIBE: '/e-popusti/odjava',
  // COMPARE_PRODUCTS: '/uporedi',
  // NEW_PASSWORD: '/nova-lozinka',
  // RESET_PASSWORD: '/zaboravljena-lozinka',
  // E_TRUST: '/trust'
};

export default routes;
